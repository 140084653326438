import React from 'react';
import PropTypes from 'prop-types';
import styles from './passengers.module.scss';
import PassengerInput from '../../../../ui/PassengerInput/PassengerInput';
import { passengerModel } from '../../../../../models/passenger';

const Passengers = (props) => {
  const createNewPassenger = () => {
    let newPassengers = props.Passengers;
    newPassengers.push(passengerModel)
    props.setPassengers([...newPassengers]);
    return props.Passengers;
  }

  const removePassenger = (index) => {
    let newPassengers = props.Passengers;
    newPassengers.splice(index, 1);
    props.setPassengers([...newPassengers]);
    return props.Passengers;
  }

  const onPassengerChange = (newData, index) => {
    if(!newData.name) return;
    let newPassengers = props.Passengers;
    newPassengers[index] = newData;
    props.setPassengers(newPassengers);
  }
  return (
    <div className={styles.Passengers}>
      {
        props.Passengers.map((address, index) => <PassengerInput data={address} index={index} onDataChange={onPassengerChange} onRemove={removePassenger} passengersList={props.passengersList}/>)
      }
      <div className={styles.buttonContainer}>
        <button className={styles.SecondaryButton} onClick={createNewPassenger}>Adicionar passageiro</button>
      </div>
    </div>
  );
}

Passengers.propTypes = {
  Passengers: PropTypes.array,
  setPassengers: PropTypes.func,
  passengersList: PropTypes.array
};

Passengers.defaultProps = {};

export default Passengers;
