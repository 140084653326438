import React from 'react';
import './App.scss';
import NpRoutes from './routes';
import NewpraNavbar from './components/ui/Navbar/Navbar'

// import the library
import { library } from '@fortawesome/fontawesome-svg-core'

// import your icons
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

function App() {
  return (
    <div>
      <NewpraNavbar></NewpraNavbar>
      <NpRoutes/>
    </div>
  );
}

export default App;
library.add(fab, fas, far)