import React, { useContext, useState } from 'react';
import styles from './login.module.scss';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { AuthContext } from "../../../providers/AuthProvider";
import ErrorModal from '../../ui/ErrorModal/ErrorModal';
import { getErrorMessage } from '../../../consts/firebaseErrorMessages';

const Login = () => {
  const { loginUser, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [ ErrorModalMessage, setErrorModalMessage ] = useState('');

  const onLogin = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    loginUser(email, password).then((result) => {
        if(user.userInfo) {
          return navigate("/");
        }
        return navigate("/register");
    }).catch(e => {
      setErrorModalMessage(getErrorMessage(e.code));
    });
  }

  if(user) {
    navigate("/");
  }

  const onSignUp = () => {
    navigate("/signUp")
  }

  const onModalClose = () => {
    setErrorModalMessage('');
  }
  return (
    <div className={styles.Login}>
      <div className={styles.Container}>
        <div className={styles.Header}>
          <h1>Seja bem vindo!</h1>
        </div>
        <p>Efetue login inserindo seu email e senha de acesso</p>
        <Form className='w-100 h-100 d-grid gap-2' onSubmit={onLogin}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Email</Form.Label>
            <Form.Control type="text" placeholder="Email" name="email" required/>
            <Form.Control.Feedback type="invalid">Por favor, informe seu email.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Label>Senha</Form.Label>
            <Form.Control type="password" placeholder="Senha" name='password' required/>
            <Form.Control.Feedback type="invalid">Por favor, informe seu email.</Form.Control.Feedback>
          </Form.Group>
          <button className={styles.PrimaryButton}>Entrar</button>
        </Form>
        <button className={styles.SecondaryButton} onClick={onSignUp}>Cadastrar-me</button>
      </div>
      <ErrorModal show={Boolean(ErrorModalMessage)} message={ErrorModalMessage} onClose={onModalClose}></ErrorModal>
    </div>
  )
};

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
