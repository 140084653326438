import React from 'react';
import PropTypes from 'prop-types';
import styles from './aditionalData.module.scss';
import { paymentTypesModel } from '../../../../../consts/paymentTypes';

const AditionalData = (props) => {
  return (
    <div className={styles.AditionalData}>
      <div className={styles.sectionContainer}>
        <h5>informações do pedido</h5>
        <div className={styles.formGrid3}>
          <input className={styles.TextInput} type='text' value={props.data.costCenter} placeholder='Centro de custos' onChange={($event) => props.setData({...props.data, costCenter: $event.target.value})}></input>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <h5>Forma de pagamento <strong className='text-danger'>*</strong></h5>
        {paymentTypesModel.map(function(paymentType) {
          return (
            <div className={styles.radioInput}>
              <input type="radio" value={paymentType.value} onChange={($event) => props.setData({...props.data, paymentMethod: $event.target.value})} checked={props.data.paymentMethod === paymentType.value}></input>
              <label>
                {paymentType.label}
              </label>
            </div>
          )
        })}
      </div>
      <div className={styles.sectionContainer}>
        <h5>Observações</h5>
        <textarea 
          rows={5}
          className={styles.textArea}
          value={props.data.obs} 
          placeholder='Insira informações adicionais que possam ser uteis para o motorista' 
          onChange={($event) => props.setData({...props.data, obs: $event.target.value})} 
        >

        </textarea>
      </div>
    </div>
  );
}

AditionalData.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func
};

AditionalData.defaultProps = {};

export default AditionalData;
