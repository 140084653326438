import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { formatAddress } from "../components/utils/utils"
import moment from "moment"

function DetailsButton(onClick) {
    return {
        name: 'DETALHES',
        cell: (row, index, column, id) => <FontAwesomeIcon icon="fa-regular fa-pen-to-square" onClick={() => onClick(row.uid)}></FontAwesomeIcon>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true
    }
}

export function homeColumns(onClick, isDriver = false) {
    
    return [
        {
            name: 'DATA DA VIAGEM',
            selector: row => row.date,
            sortable: true,
            reorder: true,
        },
        {
            name: 'MOTORISTA',
            selector: row => row.driverName,
            sortable: true,
            omit: isDriver,
            reorder: true,
        },
        {
            name: 'PASSAGEIRO',
            selector: row => row.passenger,
            sortable: true,
            reorder: true,
        },
        {
            name: 'CIDADE ORIGEM',
            selector: row => row.origin,
            sortable: true,
            reorder: true,
        },
        {
            name: 'STATUS',
            selector: row => row.status,
            sortable: true,
            reorder: true,
        },
        {
            name: 'VOUCHER',
            selector: row => row.voucher,
            sortable: true,
            reorder: true,
        },
        DetailsButton(onClick)
    ]
}

export function CarsColumns(onClick, mobile = false) {
    return [
        {
            name: 'MARCA',
            selector: row => row.brand,
        },
        {
            name: 'MODELO',
            selector: row => row.model
        },
        {
            name: 'PLACA',
            selector: row => row.license,
        },
        {
            name: 'COR',
            selector: row => row.color,
        },
        DetailsButton(onClick)
    ]
}

export function AddressColumns(onClick, isAdmin = false) {
    if(isAdmin) {
        return [
            {
                name: 'Proprietário do endereço',
                selector: row => row.ownerName,
            },
            {
                name: 'Apelido',
                selector: row => row.name,
            },
            {
                name: 'Endereço',
                selector: row => formatAddress({address: row}),
                format: (row, index) => formatAddress({address: row}),
            },
            DetailsButton(onClick)
        ]
    }
    return [
        {
            name: 'Apelido',
            selector: row => row.name,
        },
        {
            name: 'Endereço',
            selector: row => formatAddress({address: row}),
            format: (row, index) => formatAddress({address: row}),
        },
        DetailsButton(onClick)
    ]
}

export function UsersColumns(onClick, mobile = false) {
    if (mobile) {
        return [
            {
                name: 'NOME',
                selector: row => row.name,
            },
            DetailsButton(onClick)
        ]
    }
    return [
        {
            name: 'NOME',
            selector: row => row.name,
        },
        {
            name: 'TIPO DE USUÁRIO',
            selector: row => row.userType,
        },
        {
            name: 'TELEFONE DE CONTATO',
            selector: row => row.phone
        },
        {
            name: 'CPF/CNPJ',
            selector: row => row.identification,
        },
        {
            name: 'STATUS',
            selector: row => row.status,
        },
        DetailsButton(onClick)
    ]
}

const formatData = (data) => {
    const address = data.split(', ')
    return address.slice(0, 2).join(', ')
}

export function TripColumns() {
    return [
        {
            name: 'Partida',
            selector: row => row.date,
            center: true,
            wrap: true
        },
        {
            name: 'Origem',
            selector: row => formatData(row.origin),
            format: (row, index) => formatData(row.origin),
            center: true,
            wrap: true
        },
        {
            name: 'Destino',
            selector: row => row.destiny,
            format: (row, index) => formatData(row.destiny),
            center: true,
            wrap: true
        }
    ]
}

export function PassengersColumns() {
    return [
        {
            name: 'Nome',
            selector: row => row.name,
            center: true,
            wrap: true
        },
        {
            name: 'Telefone',
            selector: row => row.phone,
            center: true,
            wrap: true
        }
    ]
}

export function HomePassengersColumns(onClick, isAdmin=false) {
    if(isAdmin) {
        return [
            {
                name: 'Responsável pelo passageiro',
                selector: row => row.ownerName,
            },
            {
                name: 'Nome',
                selector: row => row.name,
            },
            {
                name: 'Telefone',
                selector: row => row.phone,
            },
            {
                name: 'Data de nascimento',
                selector: row => row.birth,
            },
            DetailsButton(onClick)
        ]
    }
    return [
            {
                name: 'Nome',
                selector: row => row.name,
            },
            {
                name: 'Telefone',
                selector: row => row.phone,
            },
            {
                name: 'Data de nascimento',
                selector: row => row.birth,
            },
            DetailsButton(onClick)
    ]
}

const formatMoney = (value) => {
    if (!value) return "";
    return `R$ ${value.toFixed(2)}`
}

const getTotalItem = (bItem) => {
    if (bItem.type === 'dynamic') return bItem.total;
    return (bItem.quantity * bItem.unity) - bItem.discount;
}

export function ValuesColumns(onClick = null, mobile = false) {
    if (mobile) {
        return [
            {
                name: 'Descrição',
                selector: row => row.description,
                center: true,
                wrap: true
            },
            {
                name: 'Total',
                format: row => formatMoney(row.total),
                selector: row => row.total,
                center: true,
                wrap: true
            }
        ]
    }
    let columns = [
        {
            name: 'Descrição',
            selector: row => row.description,
            center: true,
            wrap: true
        },
        {
            name: 'Quantidade',
            selector: row => row.quantity,
            center: true,
            wrap: true
        },
        {
            name: 'Valor unitário',
            format: row => formatMoney(row.unity),
            selector: row => row.unity,
            center: true,
            wrap: true
        },
        {
            name: 'Desconto',
            format: row => formatMoney(row.discount),
            selector: row => row.discount,
            center: true,
            wrap: true
        },
        {
            name: 'Total',
            format: row => formatMoney(getTotalItem(row)),
            selector: row => getTotalItem(row),
            center: true,
            wrap: true
        }
    ]
    if(typeof(onClick) === 'function') {
        columns.push({ 
            name: 'Remover',
            cell: (row, index, column, id) => row.type !== "dynamic" && <FontAwesomeIcon icon="fa-solid fa-trash" onClick={() => onClick(index)}></FontAwesomeIcon>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        })
    }
    return columns
}

export function DriversScheduleColumns() {
    return [
        {
            name: 'VOUCHER',
            selector: row => row.voucher,
            center: true,
            wrap: true
        },
        {
            name: 'Data/Hora',
            selector: row => row.startDate.toDate().toLocaleString(),
            center: true,
            wrap: true
        },
        {
          name: 'Origem',
          selector: row => row.origin,
          center: true,
          wrap: true
        },
        {
            name: 'Destino',
            selector: row => row.destin,
            center: true,
            wrap: true
        }
      ]
}

export function customSort (rows, selector, direction) {
    return rows.sort((rowA, rowB) => {
        // use the selector function to resolve your field names by passing the sort comparitors
        let aField = selector(rowA)
        let bField = selector(rowB)
        
        if(moment(selector(rowA), "DD/MM/YYYY, HH:mm:ss", true).isValid()){
            aField = moment(selector(rowA), "DD/MM/YYYY, HH:mm:ss", true)
            bField = moment(selector(rowB), "DD/MM/YYYY, HH:mm:ss", true)
        }
        
        let comparison = 0;
        
        if (aField > bField) {
         comparison = 1;
        } else if (aField < bField) {
         comparison = -1;
        }
      
        return direction === 'desc' ? comparison * -1 : comparison;
    });
}