import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './trip.module.scss';
import TripAddressInput from '../../../../ui/TripAddressInput/TripAddressInput';
import { routeAddressModel } from '../../../../../models/routeAddress';

const Trip = (props) => {
  const [ data, setData ] = useState([]);

  const createNewAddress = () => {
    const index = data.length - 1;
    let newAddresses = data;
    newAddresses.splice(index, 0, {title: `Endereço da parada ${index}`, address: {...routeAddressModel}})
    setData([...newAddresses]);
    return data;
  }

  useEffect(() => {
    setData(props.Addresses);
  }, [])

  useEffect(() => {
    props.setaddresses(data)
  }, [data])

  const removeAddress = (index) => {
    let newAddresses = data;
    newAddresses.splice(index, 1);
    setData([...newAddresses]);
    return data;
  }

  const onAddressChange = (newData, index) => {
    let newAddresses = data;
    newAddresses[index].address = newData;
    setData(newAddresses);
  }

  return (
    <div className={styles.Trip}>
      {
        data.map((address, index) => <TripAddressInput title={address.title} last={index === data.length - 1 } data={address.address} index={index} onDataChange={onAddressChange} onRemove={removeAddress} key={'trip'+index} addressesList={props.addressList}/>)
      }
      <div className={styles.buttonContainer}>
        <button className={styles.SecondaryButton} onClick={createNewAddress}>Adicionar parada</button>
      </div>
    </div>
  );
} 

Trip.propTypes = {
  Addresses: PropTypes.array,
  setaddresses: PropTypes.func,
  addressList: PropTypes.array
};

Trip.defaultProps = {};

export default Trip;
