export const userDataModel = {
    email: "",
    name: "",
    cpf_cnpj: "",
    cnh: "",
    contact_email: "",
    contact_phone: "",
    contact_celphone: "",
    notifications: false,
    address: {
        street: "",
        number: "",
        district: "",
        city: "",
        state: "",
        cep: "",
        complement: ""
    },
    photoUrl: ""
}