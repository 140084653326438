
export function FunctionsService () {

    const _getHeader = () => {
        return {
            'Access-Control-Allow-Origin':'*',
            'content-Type': 'application/json'
        }
    }

    const getGuestSolicitation = (body) => {
        return new Promise(async (resolve, reject) => {
            fetch(process.env.REACT_APP_FUNCTIONS_GUEST_SOLICITATION, {method: 'POST', body: JSON.stringify(body), headers: _getHeader()}).then(res => {
                if(res.status === 400) reject({status: res.status, message: 'Informações inválidas'})
                if(res.status !== 200) reject(res);
                resolve(res.json());
            }).catch(e => {
                reject(e);
            })
        })
    }

    return {
        getGuestSolicitation
    }
}