import { passengerModel } from "./passenger";
import { routeAddressModel } from "./routeAddress";

export const SolicitationModel = {
    type: "",
    routeAdresses: [routeAddressModel],
    passengers: [passengerModel],
    costCenter: "",
    obs: "",
    paymentMethod: "",
    status: ""
}