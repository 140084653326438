import React from 'react';
import PropTypes from 'prop-types';
import styles from './InfoModal.module.scss';
import { Modal } from 'react-bootstrap';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InfoModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.onClose}  aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className={styles.SolicitationModal}>
        <div className='text-center m-3'>
          <div>
            <FontAwesomeIcon icon={ faInfoCircle } size='4x' color='yellow'/>
            <h4 className='text-info'>{props.message}</h4>
          </div>
          <div className={styles.buttons}>
            <button className={styles.SecondaryButton} onClick={() => props.onClose()}>Continuar</button>
            <button className={styles.DangerButton} onClick={() => props.onCancel()}>Cancelar</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

InfoModal.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
};

InfoModal.defaultProps = {};

export default InfoModal;
