const ErrorMessages = {
    auth: [
        {
            code: 'invalid-email',
            message: 'Usuário não encontrado'
        },
        {
            code: 'default',
            message: 'Não foi possivel efetuar o login'
        },
        {
            code: 'wrong-password',
            message: 'Senha inválida.'
        }
    ]
}

export const getErrorMessage = (fbCode) => {
    const messageType = fbCode.split('/')[0];
    const messageCode = fbCode.split('/')[1];
    let message = ErrorMessages[messageType].find(error => error.code === messageCode);
    if(!message) {
        message = ErrorMessages[messageType].find(error => error.code === 'default');
    }
    return message.message
}