import { useContext } from "react";
import { AuthContext } from "../../../providers/AuthProvider";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children, userPermission = "USR" }) => {
  const { loading, user } = useContext(AuthContext);

  if (loading) {
    return <span className="loading loading-dots loading-lg"></span>;
  }

  if(!user) return <Navigate to="/login" />;

  if(userPermission === "USR") return children;

  if(userPermission === "DRV" && (user.userInfo.isAdmin || user.userInfo.isDriver)) return children;

  if(userPermission === "ADM" && user.userInfo.isAdmin) return children;

  return <Navigate to="/" />
};



PrivateRoute.propTypes = {
  children: PropTypes.node,
  userPermission: PropTypes.string
};

export default PrivateRoute;