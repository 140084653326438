import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './CarModal.module.scss';
import Modal from 'react-bootstrap/Modal';
import { AuthContext } from '../../../providers/AuthProvider';
import { CarService } from '../../../services/car.service';
import { Form } from 'react-bootstrap';

const CarModal = (props) => {
  const { user } = useContext(AuthContext);
  const carService = new CarService(user);
  const [ availableCars, setAvailableCars ] = useState();
  const [ selectedCar, setSelectedCar ] = useState();

  useEffect(() => {
    carService.getCarByDriver(user.uid).then((response) => {
      if(response.length === 1) {
        setSelectedCar(response[0]);
      }
      carService.getAvailableCars().then((cars)=> {
        setAvailableCars(cars);
      })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCarChange = (e) => {
    const car = availableCars.find(element => element.uid === e.target.value)
    setSelectedCar(car);
  }

  const sendToFirestore = () => {
    carService.changeDriver(selectedCar.uid, user.uid).then((result) => {
      props.onCarUpdated();
    })
  };

  if(!availableCars) return <div></div>
  return (
    <Modal show={props.show} onHide={props.onClose} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
          <Modal.Title>Selecione um novo carro</Modal.Title>
        </Modal.Header>
      <Modal.Body className={styles.SolicitationModal}>
        <Form.Select aria-label="Carros disponíveis para utilização" onChange={handleCarChange} className={styles.marginTopBottom}>
          {selectedCar? 
            <option value={selectedCar.uid}>{`${selectedCar.brand} ${selectedCar.model} - ${selectedCar.license}`}</option>
            :<option>Selecione um carro</option>
          }
          {
          availableCars.map(car => {
            return <option value={car.uid}>{`${car.brand} ${car.model} - ${car.license}`}</option>
          })}
        </Form.Select>
        <div className={styles.buttons}>
          <button className={styles.PrimaryButton} onClick={() => sendToFirestore()}>Utilizar o carro</button>
          <p>Não encontrou o carro desejado? <a href='/driver/car/create'>Adicione seu novo carro.</a></p>
        </div>
      </Modal.Body>
    </Modal>
  )
};

CarModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onCarUpdated: PropTypes.func
};

CarModal.defaultProps = {};

export default CarModal;
