import React from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorModal.module.scss';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const ErrorModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.onClose}  aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className={styles.SolicitationModal}>
        <div className='text-center m-3'>
          <div>
            <FontAwesomeIcon icon={ faExclamationTriangle } size='4x' color='red'/>
            <h4 className='text-danger'>Opa, ocorreu um erro durante o processamento da sua solicitação.</h4>
          </div>
          <p>{props.message}</p>
          <div>
            <button className={styles.SecondaryButton} onClick={() => props.onClose()}>Voltar</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

ErrorModal.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string,
  onClose: PropTypes.func
};

ErrorModal.defaultProps = {};

export default ErrorModal;
