import { getDocs, query, collection, addDoc, where, getDoc, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../utils/fbConnector";

export function PassengerService (user={}) {
    const savePassenger = (data) => {
        let newData = {...data};
        newData.owner = data.owner ? data.owner : user.uid;
        newData.ownerName = data.ownerName ? data.ownerName : user.userInfo.name;
        return new Promise(async (resolve, reject) => {
            try{
              const docRef = await addDoc(collection(db, `passengers`), newData)
              resolve(docRef.id);
            } catch (e) {
              reject(e);
            }
        })
    }

    const updatePassenger = (data, uid) => {
        return new Promise(async (resolve, reject) => {
            const docRef = doc(db, 'passengers', uid)
            const docSnap = await getDoc(docRef);
            if(!docSnap.exists()) reject('Não encontramos o passageiro');
            updateDoc(docRef, data).then((result) => {
                resolve();
            }).catch(e => {
                reject(e)
            })
        })
    }

    const getPassenger = (uid) => {
        return new Promise(async (resolve, reject) => {
            const docSnap = await getDoc(doc(db, 'passengers', uid));
            if(!docSnap.exists()) reject('No such document!');
            let passengersData = docSnap.data();
            passengersData.uid = docSnap.id;
            resolve(passengersData);
        })
    }

    const getUserPassengers = (customOwner = '') => {
        return new Promise(async (resolve) => {
            let passengers = [];
            let q;
            if(user.userInfo.isAdmin && !customOwner) {
                q = query(collection(db, 'passengers'));
            } else if(user.userInfo.isAdmin && customOwner) {
                q = query(collection(db, "passengers"), where("owner", "==", customOwner));
            } else {
                q = query(collection(db, "passengers"), where("owner", "==", user.uid));
            }
            passengers = await getDocs(q);
            let result = []
            passengers.forEach(address => {
                const doc = address.data();
                result.push({
                    ...doc,
                    uid: address.id,
                })
            })
            resolve(result);
        })
    }

    const deletePassenger = (uid) => {
        return new Promise(async(resolve, reject) => {
            const result = await deleteDoc(doc(db, 'passengers', uid));
            resolve(result);
        })
    }

    return {
        savePassenger,
        updatePassenger,
        getPassenger,
        getUserPassengers,
        deletePassenger
    }
}