import React, { useContext } from 'react';
import Logo from '../../../assets/images/newpra_logo.png'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { AuthContext } from '../../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';

const NewpraNavbar = () => {
  const { logOut, user } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSignOut = () => {
    logOut().then(() => {
      navigate('/login');
    })
    .catch((error) => {
      console.log(error);
    })
  }
  return(
      <Navbar className="bg-body-tertiary" data-bs-theme="dark">
        <Container>
          <Navbar.Brand href="/">
            <img
              src={Logo}
              className="align-top d-none d-md-block w-25"
              alt="Newpra"
            />
            <img
              src={Logo}
              className="align-top d-md-none d-sm-block w-50"
              alt="Newpra"
            />
          </Navbar.Brand>
          {user && 
          <Navbar.Collapse className='justify-content-end' id="basic-navbar-nav">
            <Nav>
              <div className='d-none d-md-flex'>
                <Nav.Link href="/">Home</Nav.Link>
                { user.userInfo?.isAdmin && <Nav.Link href="/users">Usuários</Nav.Link> }
                { user.userInfo?.isAdmin && <Nav.Link href='/cars'>Carros</Nav.Link> }
                { (!user.userInfo?.isAdmin && !user.userInfo?.isDriver) && <Nav.Link href='/addresses'>Meus endereços</Nav.Link>}
                { user.userInfo?.isAdmin && <Nav.Link href='/admin/addresses'>Endereços</Nav.Link>}
                { (!user.userInfo?.isAdmin && !user.userInfo?.isDriver) && <Nav.Link href='/passengers'>Meus passageiros</Nav.Link>}
                { user.userInfo?.isAdmin && <Nav.Link href='/admin/passengers'>Passageiros</Nav.Link>}
                <NavDropdown title="Perfil" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/profile">Perfil</NavDropdown.Item>
                  <NavDropdown.Item onClick={handleSignOut}>
                    Sair
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
              <div className='d-md-none d-sm-block'>
              <NavDropdown title="Menu" id="basic-nav-dropdown" align={{ sm: 'start' }}>
              <NavDropdown.Item href="/">Home</NavDropdown.Item>
                { user.userInfo?.isAdmin && <NavDropdown.Item href="/users">Usuários</NavDropdown.Item> }
                { user.userInfo?.isAdmin && <NavDropdown.Item href='/cars'>Carros</NavDropdown.Item> }
                { (!user.userInfo?.isAdmin && !user.userInfo?.isDriver) && <NavDropdown.Item href='/addresses'>Endereços</NavDropdown.Item>}
                { user.userInfo?.isAdmin && <NavDropdown.Item href='/admin/addresses'>Endereços</NavDropdown.Item>}
                { (!user.userInfo?.isAdmin && !user.userInfo?.isDriver) && <NavDropdown.Item href='/passengers'>Passageiros</NavDropdown.Item>}
                { user.userInfo?.isAdmin && <NavDropdown.Item href='/admin/passengers'>Passageiros</NavDropdown.Item>}
                  <NavDropdown.Item href="/profile">Perfil</NavDropdown.Item>
                  <NavDropdown.Item onClick={handleSignOut}>
                    Sair
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </Nav>
          </Navbar.Collapse>
          }
        </Container>
      </Navbar>
  )
};

NewpraNavbar.propTypes = {};

NewpraNavbar.defaultProps = {};

export default NewpraNavbar;
