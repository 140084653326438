import { UserService } from "../services/user.service";
import { WhatsappService } from "../services/whatsapp.service";


export function validators(user) {
    const userService = new UserService(user);
    const whatsappService = new WhatsappService();

    const validateUserForm = (data, uid = null) => {
        return new Promise(async (resolve) => {

            let errors = {};
            if (user.userInfo && !user.userInfo.isAdmin && !user.userInfo.isDriver && uid) {
                await validateVoucherId(data.voucherId).catch((error) => {
                    errors.voucherId = error
                });
            }
            if (data.isDriver) {
                errors.cnh = validateRegex(data.cnh, 'CNH');
                errors.cnhValid = validateRegex(data.cnhValid, 'data');
            }
            errors.email = validateRegex(data.email, 'email', "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");
            errors.name = validateRegex(data.name, 'nome de empresa/usuário');
            errors.cpf_cnpj = validateRegex(data.cpf_cnpj, 'CPF/CNPJ', "([0-9]{2}[\\.]?[0-9]{3}[\\.]?[0-9]{3}[\\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\\.]?[0-9]{3}[\\.]?[0-9]{3}[-]?[0-9]{2})");
            errors.rg = validateRegex(data.rg, 'RG', "(^\\d{1,2}).?(\\d{3}).?(\\d{3})-?(\\d{1}|X|x$)");
            errors.contact_email = validateRegex(data.contact_email, 'email para contato', "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");
            errors.street = validateRegex(data.address.street, 'endereço');
            errors.number = validateRegex(data.address.number, 'número');
            errors.district = validateRegex(data.address.district, 'bairro');
            errors.state = validateRegex(data.address.state, 'estado');
            errors.city = validateRegex(data.address.district, 'cidade');
            errors.cep = validateRegex(data.address.cep, 'CEP', "(^[0-9]{5})-?([0-9]{3}$)");
            errors.complement = validateRegex(data.address.complement, 'ponto de referência');
            if(!data.contact_celphone && !data.contact_phone && !data.notifications) {
                Object.keys(errors).forEach(key => errors[key] === undefined && delete errors[key])
                resolve(errors);
            }
            if (!data.contact_celphone) {
                errors.contact_phone = validateRegex(data.contact_phone, 'telefone para contato', "(^[0-9]{2})?(\\s|-)?(9?[0-9]{4})-?([0-9]{4}$)");
                if(data.notifications) {
                    errors.contact_celphone = "Por favor, informe um número de Whatsapp"
                }
                Object.keys(errors).forEach(key => errors[key] === undefined && delete errors[key])
                resolve(errors);
            }
            errors.contact_celphone = validateRegex(data.contact_celphone, 'celular para contato', "(^[0-9]{2})?(\\s|-)?(9?[0-9]{4})-?([0-9]{4}$)");
            if(!data.notifications) {
                Object.keys(errors).forEach(key => errors[key] === undefined && delete errors[key])
                resolve(errors);
            }
            whatsappService.verifyNumber(data.contact_celphone).then((res) => {
                if(!res[0].exists) {
                    errors.contact_celphone = "O número informado não possui Whatsapp."
                }
            }).catch((err) => {
                errors.contact_celphone = "Não foi possível validar o número informado."
            }).finally((_) => {
                Object.keys(errors).forEach(key => errors[key] === undefined && delete errors[key])
                resolve(errors);
            })
        })
    }

    const validateCarForm = (data) => {
        const errors = {};
        Object.keys(data).forEach((key) => {
            return !['details', 'driverId'].includes(key) && data[key] === '' ? errors[key] = 'Campo obrigatório' : ''
        })
        Object.keys(errors).forEach(key => errors[key] === '' && delete errors[key]);
        return errors;
    }

    const validateAddressForm = (data) => {
        const errors = {};
        Object.keys(data).forEach((key) => {
            return !['complement', 'uid', 'datetime'].includes(key) && data[key] === '' ? errors[key] = 'Campo obrigatório' : ''
        })
        Object.keys(errors).forEach(key => errors[key] === '' && delete errors[key]);
        return errors;
    }

    const validatePassengerForm = (data) => {
        return new Promise(async (resolve) => {
            let errors = {};
            let optionalItems = ['birth']
            if(!data.notifications) {
                optionalItems.push('phone')
            }
            Object.keys(data).forEach((key) => {
                return !optionalItems.includes(key) && data[key] === '' ? errors[key] = 'Campo obrigatório' : ''
            })
            if(!data.phone || !data.notifications) {
                Object.keys(errors).forEach(key => errors[key] === '' && delete errors[key]);
                return resolve(errors);
            }
            whatsappService.verifyNumber(data['phone']).then((res) => {
                if(!res[0].exists) {
                    errors.phone = "O número informado não possui Whatsapp."
                }
            }).catch((err) => {
                errors.phone = "Não foi possível validar o número informado."
            }).finally((_) => {
                Object.keys(errors).forEach(key => errors[key] === '' && delete errors[key]);
                resolve(errors);
            })
        })
    }

    const validateVoucherId = (voucherId) => {
        return new Promise((resolve, reject) => {
            if (!voucherId) reject('Por favor, insira um voucher ID');
            userService.getUserByVoucher(voucherId).then((result) => {
                reject('Já existe um usuário com esse voucher Id')
            }).catch(error => {
                resolve();
            })
        })
    }

    const validateRegex = (data, label, regstr = null) => {
        if (!data) return `Por favor, insira um ${label} válido.`;
        if (!regstr) return;
        const re = new RegExp(regstr, "g");
        if (!data.match(re)) return `Por favor, insira um ${label} válido.`;
        return
    }

    return {
        validateUserForm,
        validateCarForm,
        validateAddressForm,
        validatePassengerForm
    }
}