import { getDoc, getDocs, doc, query, collection, addDoc, where, updateDoc, orderBy } from "firebase/firestore";
import { db } from "../utils/fbConnector";
import { SolicitationStatusModel } from "../consts/solicitationStatus";

export function SolicitationService (user) {

    const STATUS_TYPES = {
        created: "CR",
        budgeted: "BU",
        aproved: "AP",
        cancelled: "CA",
    }

    const formatAddresses = (address) => {
        const origin = `${address.name} - ${address.street}, ${address.number}, ${address.district}, ${address.city}, ${address.state} - ${address.cep}`
        return origin;
      }    

    
    const getAllUserSolicitations = (showAllSolicitations = false, userUid = user.uid) => {
        return new Promise(async (resolve) => {
            let solicitations = [];
            let q;
            if(user.userInfo && user.userInfo.isAdmin){
                q = query(collection(db, "solicitations"), orderBy('startDate'));
            }else if(user.userInfo && user.userInfo.isDriver) {
                q = query(collection(db, "solicitations"), orderBy('startDate'), where("driver", "==", userUid));
            } else {
                q = query(collection(db, "solicitations"), orderBy('startDate'), where("owner", "==", userUid));
            }
            solicitations = await getDocs(q);
            let result = []
            solicitations.forEach(solicitation => {
                const doc = solicitation.data();
                if(!showAllSolicitations && [SolicitationStatusModel.tripCompleted.value, SolicitationStatusModel.solicitationCanceled.value].includes(doc.status)) return;
                let initial_passenger = '';
                let initial_route = '';
                if(doc.routeAdresses.length > 0) {
                    initial_route = doc.routeAdresses[0];
                }
                if(doc.passengers.length > 0) {
                    initial_passenger = doc.passengers[0];
                }
                result.push({
                    uid: solicitation.id,
                    voucher: doc.voucher,
                    date: initial_route.address.datetime.toDate().toISOString(),
                    passenger: initial_passenger.name,
                    origin: formatAddresses(initial_route.address),
                    status: doc.status,
                    driverId: doc.driver
                })
            })
            resolve(result);
        })
    };

    const getDriversSolicitation = (driverId, startDate, endDate) => {
        return new Promise(async (resolve) => {
            let solicitations = [];
            // TODO: Rever a lógica de busca de solicitações para exibição
            let q = query(collection(db, "solicitations"), where("driver", "==", driverId), 
                                                           where("startDate", ">=", new Date()),
                                                           where("startDate", "<=", endDate));
            solicitations = await getDocs(q);
            let result = []
            solicitations.forEach(solicitation => {
                const doc = solicitation.data();
                let initial_route = doc.routeAdresses[0];
                let end_route = doc.routeAdresses[doc.routeAdresses.length - 1];
                result.push({
                    uid: solicitation.id,
                    voucher: doc.voucher,
                    startDate: doc.startDate,
                    endDate: doc.endDate,
                    origin: formatAddresses(initial_route.address),
                    destin: formatAddresses(end_route.address),
                    status: doc.status,
                })
            })
            resolve(result);
        })
    };

    const filterSolicitations = (status, driverId, startDate, endDate) => {
        return new Promise(async (resolve) => {
            let solicitations = [];
            let q;
            if(user.userInfo && user.userInfo.isAdmin){
                q = query(collection(db, "solicitations"), orderBy('startDate'));
            }else if(user.userInfo && user.userInfo.isDriver) {
                q = query(collection(db, "solicitations"), orderBy('startDate'), where("driver", "==", user.uid));
            } else {
                q = query(collection(db, "solicitations"), orderBy('startDate'), where("owner", "==", user.uid));
            }
            solicitations = await getDocs(q);
            let result = []
            let tempResult = []
            solicitations.forEach(solicitation => {
                const doc = solicitation.data();
                let initial_route = doc.routeAdresses[0];
                let initial_passenger = '';
                if(doc.passengers.length > 0) {
                    initial_passenger = doc.passengers[0];
                }
                if(!driverId && !status && !startDate) {
                    if([SolicitationStatusModel.tripCompleted.value, SolicitationStatusModel.solicitationCanceled.value].includes(doc.status)) return;
                }
                tempResult.push({
                    uid: solicitation.id,
                    voucher: doc.voucher,
                    date: doc.startDate.toDate().toISOString(),
                    origin: formatAddresses(initial_route.address),
                    passenger: initial_passenger.name,
                    status: doc.status,
                    driver: doc.driver
                })
            })
            if(!driverId && !status && !startDate) resolve(tempResult);
            result = tempResult.filter((solicitation) => {
                if(driverId && driverId !== solicitation.driver) {
                    return false;
                }
                if(status && status !== solicitation.status ) {
                    return false;
                }
                if(startDate && endDate) {
                    const start = new Date(startDate);
                    const end = new Date(endDate);
                    const solicitationDate = new Date(solicitation.date)
                    if(solicitationDate < start || solicitationDate > end ) {
                        return false;
                    }
                }
                return true;
            })
            resolve(result);
        })
    };

    const getSolicitation = uid => {
        return new Promise(async (resolve, reject) => {
            const docSnap = await getDoc(doc(db, 'solicitations', uid));
            if (docSnap.exists()) {
                resolve(docSnap.data())
            } else {
                reject("No such document!");
            }
        })
    }
    const createSolicitation = (data, status=STATUS_TYPES.created, customOwner={}) => {
        return new Promise(async (resolve, reject) => {
            getAllUserSolicitations(true, customOwner.uid || user.uid).then((result) => {
                data.voucher = data.voucher ? data.voucher : `${customOwner.voucher || user.userInfo.voucherId}-${('000' + result.length).slice(-4)}`;
                data.owner = customOwner.uid || user.uid;
                data.status = status;
                try{
                  addDoc(collection(db, `solicitations`), data)
                  resolve()
                } catch (e) {
                  reject(e);
                }
            })
        })
    };
    const updateSolicitation = (data, uid) => {
        return new Promise(async (resolve, reject) => {
            const docRef = doc(db, 'solicitations', uid)
            const docSnap = await getDoc(docRef);
            if(!docSnap.exists()) reject('Não encontramos o novo carro');
            updateDoc(docRef, data).then((result) => {
                resolve();
            }).catch(e => {
                reject(e)
            })
        })
    };
    const deleteSolicitation = voucher => {
    }

    return {
        createSolicitation,
        getSolicitation,
        getAllUserSolicitations,
        updateSolicitation,
        deleteSolicitation,
        getDriversSolicitation,
        filterSolicitations
    }
}