import { SolicitationStatusModel } from "../../consts/solicitationStatus"

export const formatHomeData = (solicitations) => {
    return solicitations.map((solicitation) => {
        const newDate = new Date(solicitation.date).toLocaleString();
        const status = normalizeSolicitationStatus(solicitation);
        return {
            ...solicitation,
            date: newDate,
            status: status
        }
    })
}

export function normalizeSolicitationStatus(solicitation) {
    const statusKey = Object.keys(SolicitationStatusModel).find(status => SolicitationStatusModel[status].value === solicitation.status)
    return SolicitationStatusModel[statusKey].label;
}

export function getInitialDate(solicitation) {
    const initial_route = solicitation.routeAdresses[defineShippingAddressId(solicitation)];
    return initial_route.address.datetime.toDate().toISOString();
}

export function formatAddress(address) {
    return `${address.address.street}, ${address.address.number}, ${address.address.district}, ${address.address.city}, ${address.address.state} - ${address.address.cep}`
}

export const defineShippingAddressId = (solicitation) => {
  const shippingAddressId = solicitation.shippingAddressId;
  if (shippingAddressId === null) {
    return 0
  }
  if(solicitation.routeAdresses[shippingAddressId + 1].address.datetime) {
    return shippingAddressId + 1;
  }
  return shippingAddressId;
}