export const paymentTypesModel = [
    {
        label: "Cartão de débito",
        value: "CD"
    },
    {
        label: "Cartão de crédito",
        value: "CC",
    },
    {
        label: "Dinheiro",
        value: "DN"
    },
    {
        label: "PIX",
        value: "PX"
    },
    {
        label: "Faturado",
        value: "FT"
    }
]
    
