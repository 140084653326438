import React from 'react';
import PropTypes from 'prop-types';
import styles from './resume.module.scss';
import DataTable from 'react-data-table-component';
import TableExpanded from '../../../../ui/tableExpanded/tableExpanded';
import moment from 'moment/moment';
import { paymentTypesModel } from '../../../../../consts/paymentTypes';
import { PassengersColumns, TripColumns, customSort } from '../../../../../utils/dataTableColumns';

const Resume = (props) => {
  const formatAddresses = () => {
    let newRoute = props.routeTrip.map((address, index) => {
      if(index === (props.routeTrip.length - 1)) return undefined;
      const destiny = `${props.routeTrip[index+1].address.street}, ${props.routeTrip[index+1].address.number}, ${props.routeTrip[index+1].address.district}, ${props.routeTrip[index+1].address.city}, ${props.routeTrip[index+1].address.state} - ${props.routeTrip[index+1].address.cep}`;
      let route = {
        id: index,
        date: moment(address.address.datetime).locale('pt-BR').format('DD/MM/yyyy hh:mm'),
        origin: `${address.address.street}, ${address.address.number}, ${address.address.district}, ${address.address.city}, ${address.address.state} - ${address.address.cep}`
      }
      if(destiny) route.destiny = destiny
      return route
    })

    return newRoute.filter(element => element !== undefined);
  }

  const formatPassengers = () => {
    const newPassengers = props.passengers.map((passenger, index) => {
      return { ...passenger, id: index}
    })
    return newPassengers.filter(element => element !== undefined);
  }

  return (
    <div className={styles.Resume}>
      <div className={styles.sectionContainer}>
        <h6>
          Sobre a viagem
        </h6>
        <DataTable
          columns={TripColumns()}
          expandableRows
          sortFunction={customSort}
          expandableRowsComponent={TableExpanded}
          data={formatAddresses()}
          ></DataTable>
      </div>
      <div className={styles.sectionContainer}>
        <h6>
          Sobre os passageiros
        </h6>
        <DataTable
          columns={PassengersColumns()}
          data={formatPassengers()}
          expandableRows
          sortFunction={customSort}
          expandableRowsComponent={TableExpanded}
          ></DataTable>
      </div>
      <div className={styles.sectionContainer}>
        <h6>
          Informações adicionais
        </h6>
        <p><span className={styles.textTitle}>Forma de pagamento: </span>{paymentTypesModel.find(element => element.value === props.aditionalData.paymentMethod).label}</p>
        <p><span className={styles.textTitle}>Observações: </span>{props.aditionalData.obs}</p>
        <p><span className={styles.textTitle}>Centro de custos: </span>{props.aditionalData.costCenter}</p>
      </div>
    </div>
  );
}

Resume.propTypes = {
  passengers: PropTypes.array,
  routeTrip: PropTypes.array,
  aditionalData: PropTypes.object
};

Resume.defaultProps = {};

export default Resume;
