import React from 'react';
import PropTypes from 'prop-types';
import styles from './tableExpanded.module.scss';

const TableExpanded = (props) => {
  const formatMoney = (data) => {
    if(!data) return "";
    return `R$ ${data.toFixed(2)}`
  }

  return (
  <div className={styles.TableExpanded}>
    {props.data.voucher && <p><span className={styles.textTitle}>Voucher:</span> {props.data.voucher}</p>}
    {props.data.date && <p><span className={styles.textTitle}>Data da viagem:</span> {props.data.date}</p>}
    {props.data.passenger && <p><span className={styles.textTitle}>Passageiro:</span> {props.data.passenger}</p>}
    {props.data.origin && <p><span className={styles.textTitle}>Origem:</span> {props.data.origin}</p>}
    {props.data.status && <p><span className={styles.textTitle}>Status:</span> {props.data.status}</p>}
    {props.data.destiny && <p><span className={styles.textTitle}>Destino:</span> {props.data.destiny}</p>}
    {props.data.name && <p><span className={styles.textTitle}>Nome:</span> {props.data.name}</p>}
    {props.data.phone && <p><span className={styles.textTitle}>Celular:</span> {props.data.phone}</p>}
    {props.data.birth && <p><span className={styles.textTitle}>Data de nascimento:</span> {props.data.birth}</p>}
    {props.data.description && <p><span className={styles.textTitle}>Descrição:</span> {props.data.description}</p>}
    {props.data.quantity && <p><span className={styles.textTitle}>Quantidade:</span> {props.data.quantity}</p>}
    {props.data.unity && <p><span className={styles.textTitle}>Valor unitário:</span> {formatMoney(props.data.unity)}</p>}
    {props.data.discount && <p><span className={styles.textTitle}>Desconto:</span> {formatMoney(props.data.discount)}</p>}
    {props.data.total && <p><span className={styles.textTitle}>Total:</span> {formatMoney(props.data.total)}</p>}
    
  </div>
  );
}

TableExpanded.propTypes = {
  data: PropTypes.object
};

TableExpanded.defaultProps = {};

export default TableExpanded;
