export const routeAddressModel = {
    uid: '',
    name: '',
    street: "",
    number: "",
    district: "",
    city: "",
    state: "",
    cep: "",
    complement: "",
    datetime: ""
}