import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './owner.module.scss';
import { Typeahead } from 'react-bootstrap-typeahead';

const Owner = (props) => {
  const [ data, setData ] = useState()

  const handleOwnerChange = (e) => {
    if(e.length === 0) {
      setData({});
      return
    }
    const selectedOwner = props.ownersList.find(passenger => passenger.uid === e[0].uid);
    const newData = selectedOwner;
    setData(newData);
    props.onDataChange(newData, props.index);
  }

  const onFocus= ({ target }) => {
    target.setAttribute('autoComplete', 'off');
  }

  return (
    <div className={styles.Owner}>
      <div className={styles.sectionContainer}>
        <h5>Selecionar o dono da solicitação</h5>
        <p>Selecione um dono para a solicitação.</p>
        <div className={styles.formGrid3}>
          <Typeahead
            id='filterClients'
            labelKey="name"
            onChange={handleOwnerChange}
            options={props.ownersList}
            placeholder='Pesquisar'
            onFocus={onFocus}
          />
        </div>
      </div>
    </div>
  );
};

Owner.propTypes = {
  onDataChange: PropTypes.func,
  ownersList: PropTypes.array
};

Owner.defaultProps = {};

export default Owner;
