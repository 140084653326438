import React, { useContext, useEffect, useState } from 'react';
import styles from './CarUpdate.module.scss';
import Form from 'react-bootstrap/Form';
import { Col, Row } from 'react-bootstrap';
import { carModel } from '../../../models/cars';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../../providers/AuthProvider';
import { validators } from '../../../utils/validators';
import { CarService } from '../../../services/car.service';
import ErrorModal from '../../ui/ErrorModal/ErrorModal';
import SuccessModal from '../../ui/SuccessModal/SuccessModal';
import { UserService } from '../../../services/user.service';
import { DriverService } from '../../../services/driver.service';

const CarUpdate = () => {
  const [ data, setData ] = useState(carModel);
  const [ errors, setErrors ] = useState({});
  const navigate = useNavigate();
  const { user } = useContext(AuthContext)
  const formValidators = new validators(user);
  const [ validated, setValidated ] = useState(false);
  const uid = useParams().uid
  const [usersList, setUsersList] = useState([]);
  const carService = new CarService(user);
  const [ ErrorModalMessage, setErrorModalMessage ] = useState('');
  const [ SuccessModalMessage, setSuccessModalMessage ] = useState('');

  useEffect(() => {
    if(uid) {
      carService.getCarInfo(uid).then((result) => {
        setData(result);
        if(user.userInfo.isAdmin) {
          const driverService = new DriverService(user);
          driverService.getAllDrivers().then((result) => {
            const users = result;
            setUsersList(users);
          })
        }
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if((!errors || Object.keys(errors).length === 0) && validated){
      sendToFirestore();
    };
  }, [errors]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const handleOwner = (e) => {
    const owner = usersList.find(selectedUser => selectedUser.id === e.target.value);
    const newData = { ...data, driverId:owner.id, driver: {id: owner.id, name: owner.name}};
    setData(newData);
  }

  const handleSubmit = (event) => {
    setErrors({});
    event.preventDefault();
    setErrors(formValidators.validateCarForm(data));
    setValidated(true);
  }

  const sendToFirestore = async () => {
    if(data.driverId) {
      carService.getCarByDriver(data.driverId).then((result) => {
        carService.updateCar({driverId:'', driver:{id: '', name: ''}}, result[0].uid).then((result) => {
          carService.updateCar(data, uid).then((result) => {
            return setSuccessModalMessage('Carro atualizado com sucesso!');
          }).catch(error => {
            return setErrorModalMessage('Não foi possivel atualizar as informações do carro no momento, por favor, tente mais tarde.');
          })
        }).catch(error => {
          return setErrorModalMessage('Não foi possivel atualizar as informações do carro no momento, por favor, tente mais tarde.');
        })
      }).catch((_) => {
        carService.updateCar(data, uid).then((result) => {
          return setSuccessModalMessage('Carro atualizado com sucesso!');
        }).catch(error => {
          return setErrorModalMessage('Não foi possivel atualizar as informações do carro no momento, por favor, tente mais tarde.');
        })
      })
    } else {

      carService.updateCar(data, uid).then((result) => {
        setSuccessModalMessage('Carro atualizado com sucesso!');
      }).catch(error => {
        setErrorModalMessage('Não foi possivel atualizar as informações do carro no momento, por favor, tente mais tarde.');
      })
    }
  };

  const onModalClose = () => {
    setSuccessModalMessage('');
    setErrorModalMessage('');
    navigate(`/car/${uid}`)
  }

  return (
    <Form noValidate onSubmit={handleSubmit} className={styles.CarUpdate}>
        <div className={styles.sectionContainer}>
          <h6>Dados do Veiculo</h6>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="brandInput">
              <Form.Label>Marca</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.brand)}
                type="text"
                name='brand'
                placeholder="Marca"
                value={data.brand} 
                onChange={handleChange}
              />
              {errors?.brand && <Form.Control.Feedback type='invalid'>{errors?.brand}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="modelInput">
              <Form.Label>Modelo</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.model)}
                type="text"
                name='model'
                placeholder="Modelo"
                value={data.model} 
                onChange={handleChange}
              />
              {errors?.model && <Form.Control.Feedback type='invalid'>{errors?.model}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="licenseInput">
              <Form.Label>Placa</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.license)}
                type="text"
                placeholder="Placa"
                name='license'
                value={data.license} 
                onChange={handleChange}
              />
              {errors?.license && <Form.Control.Feedback type='invalid'>{errors?.license}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="colorInput">
              <Form.Label>Cor</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.color)}
                type="text"
                placeholder="Cor"
                value={data.color} 
                name='color'
                onChange={handleChange}
              />
              {errors?.color && <Form.Control.Feedback type='invalid'>{errors?.color}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group as={Col} md="8" controlId="detailsInput">
              <Form.Label>Detalhes (Opcional)</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.details)}
                type="text"
                placeholder="Detalhes"
                name='details'
                value={data.details} 
                onChange={handleChange}
              />
              {errors?.details && <Form.Control.Feedback type='invalid'>{errors?.details}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="ownerInput">
              <Form.Label>Nome do proprietário</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.owner)}
                type="text"
                placeholder="Proprietário"
                name='owner'
                value={data.owner} 
                onChange={handleChange}
              />
              {errors?.owner && <Form.Control.Feedback type='invalid'>{errors?.owner}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="ownerIdentificationInput">
              <Form.Label>CPF/CNPJ do proprietário</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.ownerIdentification)}
                type="text"
                placeholder="CPF/CNPJ do proprietário"
                value={data.ownerIdentification}
                name='ownerIdentification'
                onChange={handleChange}
              />
              {errors?.ownerIdentification && <Form.Control.Feedback type='invalid'>{errors?.ownerIdentification}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="documentIDInput">
              <Form.Label>Renavam</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.documentID)}
                type="text"
                placeholder="Renavam"
                name='documentID'
                value={data.documentID} 
                onChange={handleChange}
              />
              {errors?.documentID && <Form.Control.Feedback type='invalid'>{errors?.documentID}</Form.Control.Feedback>}
            </Form.Group>
          </Row>
        </div>
        {user.userInfo.isAdmin && 
          <div className={styles.sectionContainer} >
            <h6>Dados do Motorista</h6>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="owner">
                <Form.Label>Motorista atual <strong className='text-danger'>*</strong></Form.Label>
                <Form.Select
                  name='owner'
                  aria-label="Selecione um motorista"
                  onChange={handleOwner}>
                    {usersList && usersList.map(owner => <option value={owner.id}>{owner.name}</option>)}
                </Form.Select>
              </Form.Group>
            </Row>
          </div>
        }
        {
          data.driver && 
        <div className={styles.sectionContainer}>
          <h6>Motorista atual:</h6>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="brandInput">
              <Form.Label>Nome do motorista atual</Form.Label>
              <p>{data.driver.name}</p>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="brandInput">
              <button className={styles.PrimaryButton} onClick={() => navigate('/profile/' + data.driver.id)}>Visualizar perfil do motorista.</button>
            </Form.Group>
          </Row>
        </div>
        }
        <div className={styles.InputContainer}>
          <div className={styles.Buttons}>
            <button className={styles.PrimaryButton} type='submit'>Confirmar</button>
            <button className={styles.SecondaryButton} onClick={() => window.history.back()}>Voltar</button>
          </div>
        </div>
        <ErrorModal show={Boolean(ErrorModalMessage)} message={ErrorModalMessage} onClose={onModalClose}></ErrorModal>
        <SuccessModal  show={Boolean(SuccessModalMessage)} message={SuccessModalMessage} onClose={onModalClose}></SuccessModal>
      </Form>
  )
};

CarUpdate.propTypes = {};

CarUpdate.defaultProps = {};

export default CarUpdate;
