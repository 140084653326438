import React from 'react';
import PropTypes from 'prop-types';
import styles from './SolicitationModal.module.scss';
import Modal from 'react-bootstrap/Modal';

const SolicitationModal = (props) => {
  return (
    <Modal show={props.show} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered onHide={props.onClose} >
      <Modal.Header closeButton>
        <Modal.Title>Tipo de serviço</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.SolicitationModal}>
        <p>Indique o tipo de serviço que será solicitado: </p>
        <div className={styles.buttons}>
          <button className={styles.PrimaryButton} onClick={() => props.onSelectOption("O")}>Solicitar orçamento</button>
          <button className={styles.SecondaryButton} onClick={() => props.onSelectOption("S")}>Solicitar serviço</button>
        </div>
      </Modal.Body>
    </Modal>
  )
};

SolicitationModal.propTypes = {
  show: PropTypes.bool,
  onSelectOption: PropTypes.func,
  onClose: PropTypes.func
};

SolicitationModal.defaultProps = {};

export default SolicitationModal;
