import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './guestInfoModal.module.scss';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import InputMask from "react-input-mask";

const GuestInfoModal = (props) => {
  const [data, setData] = useState({});

  useEffect(() => {
    if(props.data) {
      setData(props.data);
    }
  }, [])

  const handleChange = (e) => {
    const newData = {...data, [e.target.name]: e.target.value}
    setData(newData);
  }

  return (
    <Modal show={props.show} onHide={props.onClose}  aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title>Seja bem vindo passageiro NEWPRA!</Modal.Title>
        
      </Modal.Header>
      <Modal.Body className={styles.SolicitationModal}>
      <Form.Label>Este é o seu portal para visualização de mais detalhes sobre sua próxima viagem!</Form.Label>
      <Form.Label>Para que possamos continuar com sua consulta, por favor, nos informe os seguintes dados.</Form.Label>
        <Form.Group>
          <Form.Label>Data de nascimento</Form.Label>
          <InputMask type='text' name='birth' mask="99/99/9999" className='form-control' placeholder='Data de nascimento' value={data.birth} onChange={handleChange}></InputMask>
        </Form.Group>
        <Form.Group>
        <Form.Label>Telefone/Celular</Form.Label>
        <InputMask type='text' name='phone' mask="(99) 9 9999-9999" className='form-control' placeholder='Telefone' value={data.phone} onChange={handleChange}></InputMask>
        </Form.Group>
        <div className={styles.buttons}>
          <button className={styles.PrimaryButton} onClick={() => props.onSend(data)}>Continuar</button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

GuestInfoModal.propTypes = {
  show: PropTypes.bool,
  onSend: PropTypes.func,
  data: PropTypes.object
};

GuestInfoModal.defaultProps = {};

export default GuestInfoModal;
