import { getDocs, query, collection, addDoc, where, getDoc, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../utils/fbConnector";

export function AddressService (user={}) {
    
    const getAddressByCEP = (cep) => {
        return new Promise(async (resolve, reject) => {
            fetch(`https://viacep.com.br/ws/${cep}/json/`).then(res => {
                if(res.error) reject(res.json());
                resolve(res.json());
            }).catch(e => {
                reject(e);
            })
        })
    }

    const saveAddress = (data) => {
        let newData = {...data};
        newData.owner = data.owner ? data.owner : user.uid;
        newData.ownerName = data.ownerName ? data.ownerName : user.userInfo.name;
        delete newData.datetime;
        delete newData.uid;
        return new Promise(async (resolve, reject) => {
            try{
              const docRef = await addDoc(collection(db, `addresses`), newData)
              resolve(docRef.id);
            } catch (e) {
              reject(e);
            }
        })
    }

    const updateAddress = (data, uid) => {
        return new Promise(async (resolve, reject) => {
            const docRef = doc(db, 'addresses', uid)
            const docSnap = await getDoc(docRef);
            if(!docSnap.exists()) reject('Não encontramos o endereço');
            updateDoc(docRef, data).then((result) => {
                resolve();
            }).catch(e => {
                reject(e)
            })
        })
    }

    const getAddress = (uid) => {
        return new Promise(async (resolve, reject) => {
            const docSnap = await getDoc(doc(db, 'addresses', uid));
            if(!docSnap.exists()) reject('No such document!');
            let addressData = docSnap.data();
            addressData.uid = docSnap.id;
            resolve(addressData);
        })
    }

    const getUserAddresses = (customOwner = '') => {
        return new Promise(async (resolve) => {
            let addresses = [];
            let q;
            if(user.userInfo.isAdmin && !customOwner) {
                q = query(collection(db, 'addresses'));
            } else if(customOwner && user.userInfo.isAdmin) {
                q = query(collection(db, "addresses"), where("owner", "in", [customOwner, 'publico']));
            } else {
                q = query(collection(db, "addresses"), where("owner", "in", [user.uid, 'publico']));
            }
            addresses = await getDocs(q);
            let result = []
            addresses.forEach(address => {
                const doc = address.data();
                result.push({
                    ...doc,
                    uid: address.id,
                    name: doc.owner === 'publico' ? 'Publico - ' + doc.name : doc.name
                })
            })
            resolve(result);
        })
    }

    const deleteAddress = (addressUid) => {
        return new Promise(async(resolve, reject) => {
            const result = await deleteDoc(doc(db, 'addresses', addressUid));
            resolve(result);
        })
    }

    return {
        getAddressByCEP,
        saveAddress,
        getAddress,
        getUserAddresses,
        updateAddress,
        deleteAddress
    }
}