export const carModel = {
    brand: "",
    model: "",
    license: "",
    color: "",
    documentID: "",
    owner: "",
    ownerIdentification: "",
    creator: "",
    details: ""
}