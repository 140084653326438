import React from 'react';
import PropTypes from 'prop-types';
import styles from './SuccessModal.module.scss';
import { Modal } from 'react-bootstrap';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SuccessModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.onClose}  aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className={styles.SolicitationModal}>
        <div className='text-center m-3'>
          <div>
            <FontAwesomeIcon icon={ faCheckCircle } size='4x' color='green'/>
            <h4 className='text-success'>{props.message}</h4>
          </div>
          <div>
            <button className={styles.SecondaryButton} onClick={() => props.onClose()}>Continuar</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

SuccessModal.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string,
  onClose: PropTypes.func
};

SuccessModal.defaultProps = {};

export default SuccessModal;
