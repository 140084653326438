import React, { useContext, useState } from 'react';
import styles from './SignUp.module.scss';
import Logo from '../../../assets/images/newpra_logo.png'
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { AuthContext } from '../../../providers/AuthProvider';
import { updateProfile } from "firebase/auth";


const SignUp = () => {
  const navigate = useNavigate();
  const {createUser, user} = useContext(AuthContext);

  const [validated, setValidated] = useState(false); // eslint-disable-line no-unused-vars

  if (user) {
    navigate("/");
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const email = e.target.email.value;
    const password = e.target.password.value;
    createUser(email, password).then((result) => {
      updateProfile(result.user, {
        displayName: name,
      });
      navigate("/register");
    })
    .catch((error) => {
      console.log(error);
    })
  }

  return (
    <div className={styles.SignUp}>
      <div className={styles.Container}>
        <div className={styles.Header}>
          <img src={ Logo } className={styles.Logo} alt='Logo da plataforma'></img>
        </div>
        <Form noValidate onSubmit={handleSubmit} className='w-100 d-grid gap-2'>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Nome</Form.Label>
            <Form.Control type="text" placeholder="Nome do cliente" name='name' required/>
            <Form.Control.Feedback type="invalid">Por favor, informe seu nome.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" placeholder="Email" name='email' required/>
            <Form.Control.Feedback type="invalid">Por favor, informe seu email.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
            <Form.Label>Senha</Form.Label>
            <Form.Control type="password" placeholder="Insira sua senha" name='password' required/>
            <Form.Control.Feedback type="invalid">Por favor, informe sua senha.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
            <Form.Label>Repita sua senha</Form.Label>
            <Form.Control type="password" placeholder="Repita sua senha" name='confirmPwd' required/>
            <Form.Control.Feedback type="invalid">Senha incorreta.</Form.Control.Feedback>
          </Form.Group>
          <Button size="lg" type="submit" className='btn-primary'>Criar Conta</Button>
        </Form>
      </div>
    </div>
  );
}

SignUp.propTypes = {};

SignUp.defaultProps = {};

export default SignUp;
