import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from './avatarInput.module.scss';
import UserGeneric from '../../../assets/images/profile.png'

const AvatarInput = (props) => {
  const [selectedFile, setSelectedFile] = useState(null) ;

  const setPhoto = (event) => {
    var binaryData = [];
    binaryData.push(event);
    var photo = window.URL.createObjectURL(new Blob(binaryData, {type: "image/*"}))
    setSelectedFile({...selectedFile, photo});
    props.onDataChange(event)
  }


  return ( 
    <div className={styles.personal_image}>
      <label className={styles.label}>
        <input type="file" onChange={(e) => setPhoto(e.target.files[0])}/>
        <figure className={styles.personal_figure}>
          <img src={selectedFile?.photo || UserGeneric} className={styles.personal_avatar} alt="avatar" />
          <figcaption className={styles.personal_figcaption}>
            <img src="https://raw.githubusercontent.com/ThiagoLuizNunes/angular-boilerplate/master/src/assets/imgs/camera-white.png" alt="Alterar foto de perfil"/>
          </figcaption>
        </figure>
      </label>
    </div>
  )
};

AvatarInput.propTypes = {
  onDataChange: PropTypes.func
};

AvatarInput.defaultProps = {};

export default AvatarInput;
