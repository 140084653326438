import React, { useContext, useEffect, useState } from 'react';
import styles from './CarDetails.module.scss';
import { CarService } from '../../../services/car.service';
import { useNavigate, useParams } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { AuthContext } from '../../../providers/AuthProvider';
import UserGeneric from '../../../assets/images/profile.png'
import InfoModal from '../../ui/InfoModal/InfoModal';

const CarDetails = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext)
  const uid = useParams().uid
  const [data, setData] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);


  useEffect(() => {
    if(uid) {
      const carService = new CarService(user);
      carService.getCarInfo(uid).then((result) => {
        setData(result);
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const updateCar = () => {
    return navigate('/car/update/' + uid);
  };

  const deleteCar = () => {
    setShowDeleteModal(false);
    const carService = new CarService(user);
    carService.deleteCar(uid).then((result) => {
      return navigate('/cars');
    })
  }

  if(!data) return <div></div>
  return (
    <div className={styles.CarCreate}>
      <div className={styles.sectionContainer}>
        <h6>Dados do Veiculo</h6>
        <Row className="mb-3">
          <Form.Group as={Col} md="4" controlId="brandInput">
            <Form.Label>Marca</Form.Label>
            <p>{data.brand}</p>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="modelInput">
            <Form.Label>Modelo</Form.Label>
            <p>{data.model}</p>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="licenseInput">
            <Form.Label>Placa</Form.Label>
            <p>{data.license}</p>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="colorInput">
            <Form.Label>Cor</Form.Label>
            <p>{data.color}</p>
          </Form.Group>
          <Form.Group as={Col} md="8" controlId="detailsInput">
            <Form.Label>Detalhes (Opcional)</Form.Label>
            <p>{data.details}</p>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="ownerInput">
            <Form.Label>Nome do proprietário</Form.Label>
            <p>{data.owner}</p>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="ownerIdentificationInput">
            <Form.Label>CPF/CNPJ do proprietário</Form.Label>
            <p>{data.ownerIdentification}</p>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="documentIDInput">
            <Form.Label>Renavam</Form.Label>
            <p>{data.documentID}</p>
          </Form.Group>
        </Row>
      </div>
      {data.driver && data.driver.id && data.driver.name && 
        <div className={styles.sectionContainer}>
          <h6>Dados do Motorista</h6>
          <Row className="mb-3">
            <Form.Group as={Col} md="1" controlId="driverNameInput">
              <img src={data.driver.photoUrl || UserGeneric} className={styles.personal_avatar} alt="avatar" />
            </Form.Group>
            
            <Form.Group as={Col} md="9" controlId="driverNameInput">
              <Form.Label>Nome</Form.Label>
              <p>{data.driver.name}</p>
            </Form.Group>
            <Form.Group as={Col} md="2" controlId="driverNameInput">
              <button className={styles.PrimaryButton} onClick={() => navigate('/profile/' + data.driver.id)}>Visualizar perfil do motorista.</button>
            </Form.Group>
          </Row>
        </div>
      }
      <div className={styles.InputContainer}>
        <div className={styles.Buttons}>
          {
            (user.userInfo.isAdmin || user.uid === data.creator) &&
            <button className={styles.DangerButton} onClick={() => setShowDeleteModal(true)}>Excluir endereço</button>
          }
          <button className={styles.SecondaryButton} onClick={() => updateCar()}>Atualizar informações do veículo</button>
          <button className={styles.PrimaryButton} onClick={() => navigate('/cars')}>Voltar</button>
        </div>
      </div>
      <InfoModal show={Boolean(showDeleteModal)} message={'Tem certeza de que deseja remover o carro?'} onClose={() => deleteCar()} onCancel={() => setShowDeleteModal(false)}></InfoModal>
    </div>
  )
}

CarDetails.propTypes = {};

CarDetails.defaultProps = {};

export default CarDetails;
