import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "./components/pages/login/login";
import Home from "./components/pages/home/home";
import SignUp from "./components/pages/SignUp/SignUp";
import Register from "./components/pages/register/register";
import SolicitationDetails from "./components/pages/solicitation/details/details";
import Create from "./components/pages/solicitation/create/create";
import PrivateRoute from "./components/utils/privateRoute/privateRoute";
import Users from "./components/pages/users/users";
import Cars from "./components/pages/cars/cars";
import UserDetails from "./components/pages/UserDetails/UserDetails";
import UserUpdate from "./components/pages/UserUpdate/UserUpdate";
import CarCreate from "./components/pages/CarCreate/CarCreate";
import CarUpdate from "./components/pages/CarUpdate/CarUpdate";
import CarDetails from "./components/pages/CarDetails/CarDetails";
import Addresses from "./components/pages/Addresses/Addresses";
import AddressesDetails from "./components/pages/AddressesDetails/AddressesDetails";
import AddressesCreate from "./components/pages/AddressesCreate/AddressesCreate";
import AddressesUpdate from "./components/pages/AddressesUpdate/AddressesUpdate";
import Passengers from "./components/pages/Passengers/Passengers";
import PassengersCreate from "./components/pages/PassengersCreate/PassengersCreate";
import PassengersDetails from "./components/pages/PassengersDetails/PassengersDetails";
import PassengersUpdate from "./components/pages/PassengersUpdate/PassengersUpdate";
import SolicitationUpdate from "./components/pages/solicitation/update/update";
import GuestDetails from "./components/pages/solicitation/guestDetails/guestDetails";

const NpRoutes = () => {
    const navigate = useNavigate();
    return (
        <Routes>
            <Route element={<Login />} path="/login" />
            <Route element={<SignUp />} path="/signUp" />
            <Route element={<GuestDetails />} path="/guest/solicitation/:solicitation/:passenger" />
            <Route element={<PrivateRoute>< Home navigate={navigate} /> </PrivateRoute>} path="/" exact />
            <Route element={<PrivateRoute>< Register />  </PrivateRoute>} path="/register" />
            <Route element={<PrivateRoute>< SolicitationDetails />  </PrivateRoute>} path="/solicitation/detail/:uid" />
            <Route element={<PrivateRoute>< SolicitationUpdate />  </PrivateRoute>} path="/solicitation/update/:uid" />
            <Route element={<PrivateRoute>< Create />  </PrivateRoute>} path="/solicitation/create/:solicitationType" />
            <Route element={<PrivateRoute>< UserDetails />  </PrivateRoute>} path="/profile" />
            <Route element={<PrivateRoute>< UserUpdate />  </PrivateRoute>} path="/profile/update" />

            <Route element={<PrivateRoute userPermission="ADM">< UserDetails />  </PrivateRoute>} path="/profile/:uid" />
            <Route element={<PrivateRoute userPermission="ADM">< UserUpdate />  </PrivateRoute>} path="/profile/update/:uid" />
            <Route element={<PrivateRoute userPermission="DRV">< CarCreate />  </PrivateRoute>} path="/driver/car/create" />
            <Route element={<PrivateRoute userPermission="ADM">< CarCreate />  </PrivateRoute>} path="/car/create" />
            <Route element={<PrivateRoute userPermission="ADM">< CarUpdate />  </PrivateRoute>} path="/car/update/:uid" />
            <Route element={<PrivateRoute userPermission="ADM">< CarDetails />  </PrivateRoute>} path="/car/:uid" />
            <Route element={<PrivateRoute userPermission="ADM"><Users navigate={navigate} /> </PrivateRoute>} path="/users" />
            <Route element={<PrivateRoute userPermission="ADM"><Cars navigate={navigate} /></PrivateRoute>} path="/cars" />
            <Route element={<PrivateRoute userPermission="USR"><Addresses navigate={navigate} /></PrivateRoute>} path="/addresses" />
            <Route element={<PrivateRoute userPermission="USR"><AddressesCreate /></PrivateRoute>} path="/addresses/create" />
            <Route element={<PrivateRoute userPermission="USR"><AddressesUpdate /></PrivateRoute>} path="/addresses/update/:uid" />
            <Route element={<PrivateRoute userPermission="ADM"><Addresses navigate={navigate} /></PrivateRoute>} path="/admin/addresses" />
            <Route element={<PrivateRoute>< AddressesDetails />  </PrivateRoute>} path="/address/:uid" />
            <Route element={<PrivateRoute userPermission="USR"><Passengers navigate={navigate} /></PrivateRoute>} path="/passengers" />
            <Route element={<PrivateRoute userPermission="ADM"><Passengers navigate={navigate} /></PrivateRoute>} path="/admin/passengers" />
            <Route element={<PrivateRoute userPermission="USR"><PassengersCreate /></PrivateRoute>} path="/passengers/create" />
            <Route element={<PrivateRoute userPermission="USR"><PassengersDetails /></PrivateRoute>} path="/passenger/:uid" />
            <Route element={<PrivateRoute userPermission="USR"><PassengersUpdate /></PrivateRoute>} path="/passenger/update/:uid" />
        </Routes>
    )
}

export default NpRoutes;