
export const SolicitationStatusModel = {
    created: {
        value: 'CR',
        label: 'Criado',
        texts: {
            adm: 'A solicitação *[solicitation]* foi criada e já está aguardando análise.\n\n [solicitationUrl]',
            client: 'Sua solicitação *[solicitation]* foi criada e está aguardando análise da equipe.',
            passengers: 'Solicitação *[solicitation]* gerada por *[userName]*, em breve você receberá mais informações referentes a viagem!'
        }
    },
    estimativeCreated: {
        value: 'EC',
        label: 'Orçamento aguardando aprovação',
        texts: {
            client: 'Sua solicitação *[solicitation]* foi atualizada, e o orçamento já está disponível na plataforma para análise!\n\n [solicitationUrl]',
        }
    },
    estimativeAproved: {
        value: 'EA',
        label: 'Orçamento aprovado',
        texts: {
            adm: 'O orçamento para a solicitação *[solicitation]* foi aprovado pelo cliente!\n\n Aguardando novas atualizações.\n\n [solicitationUrl]',
        }
    },
    driverSelected: {
        value: 'DS',
        label: 'Motorista encontrado',
        texts: {
            client: 'Foi encontrado um motorista para a sua solicitação *[solicitation]*!\n\n [solicitationUrl]',
            driver: 'Você recebeu uma nova solicitação: *[solicitation]*.\n\nPara mais detalhes e aprovação da solicitação, acesse o link:\n\n [solicitationUrl]'
        }
    },
    driverAproved: {
        value: 'DA',
        label: 'Motorista confirmado',
        texts: {
            client: 'O motorista aprovou a solicitação *[solicitation]*!\n\n',
            adm: 'Solicitação *[solicitation]* aprovada pelo motorista.'
        }
    },
    driverOnTheWay: {
        value: 'DT',
        label: 'Motorista a caminho',
        texts: {
            client: 'Solicitação: *[solicitation]*\n\n O motorista está a caminho, aguarde no ponto de origem combinado para o embarque.',
            passengers: 'Solicitação: *[solicitation]*\n\n O motorista está a caminho, aguarde no ponto de origem combinado para o embarque.',
            adm: 'Solicitação *[solicitation]* o motorista está a caminho.'
        }
    },
    driverWaiting: {
        value: 'DW',
        label: 'Motorista aguardando',
        texts: {
            client: 'Solicitação: *[solicitation]*\n\n O motorista está no local, dirija-se ao ponto de origem para o embarque.',
            passengers: 'Solicitação: *[solicitation]*\n\n O motorista está no local, dirija-se ao ponto de origem para o embarque.',
            adm: 'Solicitação *[solicitation]* o motorista está no local.'
        }
    },
    tripInProgress: {
        value: 'TP',
        label: 'Viagem em andamento',
        texts: {
            client: 'Solicitação: *[solicitation]*\n\n Viagem iniciada.',
            adm: 'Solicitação *[solicitation]* Viagem iniciada.'
        }
    },
    passangerDisembarked: {
        value: 'PD',
        label: 'Passageiro desembarcou',
        texts: {
            client: 'Solicitação: *[solicitation]*\n\n Passageiro desembarcou.',
            adm: 'Solicitação *[solicitation]* Passageiro desembarcou.'
        }
    },
    tripCompleted: {
        value: 'TC',
        label: 'Viagem concluída',
        texts: {
            client: 'Solicitação: *[solicitation]*\n\n Viagem concluida.',
            passengers: 'Solicitação: *[solicitation]*\n\n Viagem concluida.',
            adm: 'Solicitação *[solicitation]* Viagem concluida.',
            driver: 'Solicitação *[solicitation]* Viagem concluida.'
        }
    },
    solicitationCanceled: {
        value: 'SC',
        label: 'Viagem cancelada',
        texts: {
            client: 'Solicitação: *[solicitation]*\n\n Solicitação cancelada.',
            passengers: 'Solicitação: *[solicitation]*\n\n Solicitação cancelada.',
            adm: 'Solicitação *[solicitation]* Solicitação cancelada.',
            driver: 'Solicitação *[solicitation]* Solicitação cancelada.'
        }
    }
}