import React, { useContext, useEffect, useState }  from 'react';
import PropTypes from 'prop-types';
import styles from './PassengersDetails.module.scss';
import { PassengerService } from '../../../services/passengers.service';
import { useNavigate, useParams } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { AuthContext } from '../../../providers/AuthProvider';
import UserGeneric from '../../../assets/images/profile.png'
import InfoModal from '../../ui/InfoModal/InfoModal';

const PassengersDetails = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext)
  const uid = useParams().uid
  const [data, setData] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if(uid) {
      const passengerService = new PassengerService(user);
      passengerService.getPassenger(uid).then((result) => {
        setData(result);
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const updatePassenger = () => {
    return navigate('/passenger/update/' + uid);
  };

  const goBack = () => {
    if(user.userInfo.isAdmin) {
      return navigate('/admin/passengers');
    }
    return navigate('/passengers')
  }

  const deletePassenger = () => {
    setShowDeleteModal(false);
    const passengerService = new PassengerService(user);
    passengerService.deletePassenger(uid).then((result) => {
      return goBack();
    })
  }

  if(!data) return <div></div>
  return (
    <div className={styles.CarCreate}>
      <div className={styles.sectionContainer}>
        <h6>Dados do Passageiro</h6>
        <Row className="mb-3">
          <Form.Group as={Col} md="4" controlId="nameInput">
            <Form.Label>Nome do passageiro</Form.Label>
            <p>{data.name}</p>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="phoneInput">
            <Form.Label>Telefone</Form.Label>
            <p>{data.phone}</p>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="birthInput">
            <Form.Label>Data de nascimento</Form.Label>
            <p>{data.birth}</p>
          </Form.Group>
        </Row>
      </div>
      <div className={styles.InputContainer}>
        <div className={styles.Buttons}>
          {
            (user.userInfo.isAdmin || user.uid === data.owner) &&
            <button className={styles.DangerButton} onClick={() => setShowDeleteModal(true)}>Excluir passageiro</button>
          }
          <button className={styles.SecondaryButton} onClick={() => updatePassenger()}>Atualizar informações do passageiro</button>
          <button className={styles.PrimaryButton} onClick={() => navigate('/passengers')}>Voltar</button>
        </div>
      </div>
      <InfoModal show={Boolean(showDeleteModal)} message={'Tem certeza de que deseja remover o passageiro?'} onClose={() => deletePassenger()} onCancel={() => setShowDeleteModal(false)}></InfoModal>
    </div>
  )
}

PassengersDetails.propTypes = {};

PassengersDetails.defaultProps = {};

export default PassengersDetails;
